import {Component} from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent {
  title: string = "";
  constructor() {
    window.scrollTo({top: 0, behavior: 'smooth'});
  }
}
