import {Component,} from '@angular/core';
import {portalRouteMap} from 'src/app/core/mapping-routing';
import {Router} from "@angular/router";

@Component({
  selector: 'app-portal-header',
  templateUrl: './portal-header.component.html',
  styleUrls: ['./portal-header.component.less'],
})
export class PortalHeaderComponent {

  isVisibleMenuMobile: boolean = false;

  constructor(private _router: Router) {
  }

  goHome() {
    this._router.navigate([portalRouteMap.home]);
    if (this.isVisibleMenuMobile) {
      this.toggleMenuMobile();
    }
  }

  toggleMenuMobile() {
    this.isVisibleMenuMobile = !this.isVisibleMenuMobile;
  }

}
