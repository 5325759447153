<!-- Header -->

<header class="sticky top-0">
    <nav class="container mx-auto flex flex-wrap items-center justify-between w-full py-4 md:py-0 px-4 text-lg text-gray-700 bg-white">
        <a (click)="goHome()">
            <div class="logo"></div>
        </a>
        <span (click)="toggleMenuMobile()" [nzType]="isVisibleMenuMobile ? 'menu-fold': 'menu-unfold'"
              class="cursor-pointer md:hidden block menu-fold" nz-icon
              nzTheme="outline"></span>

        <div *ngIf="!isVisibleMenuMobile" class="hidden w-full hide_menu md:flex md:items-center md:w-auto menu-web">
            <app-item-menu></app-item-menu>
        </div>
    </nav>
</header>

<!-- Fin Header -->

<ng-template #templateTitle>
    <a (click)="goHome()" class="float-right mr-4">
        <div class="logo logo-mobile"></div>
    </a>
</ng-template>

<nz-drawer
        (nzOnClose)="toggleMenuMobile()"
        [nzClosable]="true"
        [nzCloseOnNavigation]="true"
        [nzPlacement]="'left'"
        [nzTitle]="templateTitle"
        [nzVisible]="isVisibleMenuMobile"
        nzSize="default"
>
    <ng-container *nzDrawerContent>
        <div class="w-full menu-mobile">
            <app-item-menu [(isVisibleMenuMobile)]="isVisibleMenuMobile"></app-item-menu>
        </div>

    </ng-container>
</nz-drawer>
