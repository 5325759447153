<ul
  [ngClass]="{'flex-col': isVisibleMenuMobile,'items-start': isVisibleMenuMobile,'items-center': !isVisibleMenuMobile,}"
  class="flex text-base text-gray-100 mb-0 md:flex md:justify-between md:pt-0">
  <li>
    <a [ngClass]="{ active: hasActive(portalRouteMap.features) }"
       (click)="goFeature()"
       class="md:p-4 py-2 block">Fonctionnalités</a>
  </li>
  <li>
    <a (click)="goPrice()" [ngClass]="{ active: hasActive(portalRouteMap.price) }"
       class="md:p-4 py-2 block">Nos prix</a>
  </li>
  <li>
    <a (click)="goPartner()"
       [ngClass]="{ active: hasActive(portalRouteMap.partner) }"
       class="md:p-4 py-2 block">Nos partenaires</a>
  </li>
  <li>
    <a
      (click)="goAbout()"
      [ngClass]="{ active: hasActive(portalRouteMap.about) }" class="md:p-4 py-2 block">À Propos</a>
  </li>
  <li>
    <a class="md:p-4 py-2 block active"  (click)="goRegister()">
      Nous rejoindre
    </a>
  </li>
</ul>
