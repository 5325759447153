import {Component, ElementRef, HostListener} from '@angular/core';
import * as Aos from 'aos';

@Component({
  selector: 'app-layout-portal',
  templateUrl: './layout-portal.component.html',
  styleUrls: ['./layout-portal.component.less'],
})
export class LayoutPortalComponent {
  constructor(private el: ElementRef) {
  }

  ngOnInit() {
    Aos.init();
    window.scrollTo({top: 0, behavior: 'smooth'});

  }

  @HostListener('window:scroll', ['$event'])
  onScroll(event: Event) {
    const nav = this.el.nativeElement.querySelector('header');
    if (window.pageYOffset > 0) {
      nav.classList.add('add-shadow');
    } else {
      nav.classList.remove('add-shadow');
    }
  }

}
