<footer>
    <div class="w-full-grid grid grid-cols-1 sm:grid-cols-2 container mx-auto">
        <div class="logo-part w-full ">
            <div class="container-logo w-full h-full sm:w-6/12 sm:h-3/6">
                <div class="content-logo"></div>
            </div>
        </div>
        <div class="product_page-part justify-between w-full items-center sm:items-start ">
            <div class="sm:w-auto w-full products">
                <div class="title">Nos Produits</div>
                <ul>
                    <li>
                        <a href="">Gestion de frais</a>
                    </li>
                    <li>
                        <a href="https://neiba-technologies.com/home" target="_blank">Gestion d'assurance</a>
                    </li>
                    <li>
                        <a href="https://neiba-technologies.com/home" target="_blank">Gestion de parking</a>
                    </li>
                </ul>
            </div>

            <div class="sm:w-auto w-full pages">
                <div class="title">Nos Pages</div>
                <ul>
                    <li>
                        <a [routerLink]="portalRouteMap.features">Fonctionnalités</a>
                    </li>
                    <li>
                        <a [routerLink]="portalRouteMap.price">Prix</a>
                    </li>
                    <li>
                        <a [routerLink]="portalRouteMap.partner">Partenaires</a>
                    </li>
                    <li>
                        <a [routerLink]="portalRouteMap.about">À Propos</a>
                    </li>
                    <li>
                        <a [routerLink]="portalRouteMap.contact">Contact</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>

    <form action="#" class="container my-4 justify-end w-full sm:w-8/12 ml-auto">
        <div class="items-center mx-auto mb-3 space-y-4 max-w-screen-sm sm:flex sm:space-y-0">
            <div class="relative w-full">
                <label class="hidden mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                       for="email">Email address</label>
                <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                    <svg
                            class="w-5 h-5 text-gray-500 dark:text-gray-400"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                                d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"
                        ></path>
                        <path
                                d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"
                        ></path>
                    </svg>
                </div>
                <input
                        class="block p-3 pl-10 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 sm:rounded-none sm:rounded-l-lg focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                        id="email"
                        placeholder="Entrer votre email"
                        required
                        type="email"
                />
            </div>
            <div>
                <button
                        class="py-3 px-5 w-full text-sm font-medium text-center text-white rounded-lg border cursor-pointer bg-primary-700 border-primary-600 sm:rounded-none sm:rounded-r-lg hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                        type="submit">
                    Envoyer
                </button>
            </div>
        </div>
    </form>

    <div class="container mx-auto  grid grid-cols-1 sm:grid-cols-3 gap-3 items-center mt-5 mb-5 last_line">
        <a [routerLink]="portalRouteMap.home" class="text-center sm:text-start ">Politiques de confidentialités</a>
        <a [routerLink]="portalRouteMap.home" class="text-center">Tous droits réservés 2023 © BudgetPal</a>
        <a [routerLink]="portalRouteMap.home" class="text-center sm:text-end">Termes & conditions</a>
    </div>
</footer>
