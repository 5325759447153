import {Component} from '@angular/core';
import {portalRouteMap} from "../../../../core/mapping-routing";

@Component({
  selector: 'app-portal-footer',
  templateUrl: './portal-footer.component.html',
  styleUrls: ['./portal-footer.component.less']
})
export class PortalFooterComponent {


    protected readonly portalRouteMap = portalRouteMap;
}
