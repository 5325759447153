<nz-layout class="app-layout">
  <nz-sider [(nzCollapsed)]="isCollapsed"
            [nzTrigger]="null"
            class="menu-sidebar"
            nzBreakpoint="md"
            nzCollapsible
            nzWidth="256px">
    <div class="sidebar-logo">
      <a (click)="goToHome()" class="" href="#" style="display: flex">
        <img alt="logo" src="assets/logo.svg">
      </a>
    </div>
    <ul [nzInlineCollapsed]="isCollapsed" nz-menu nzMode="inline" nzTheme="dark">
      <li nz-submenu nzIcon="dashboard" nzOpen nzTitle="Dashboard">
        <ul>
          <li nz-menu-item nzMatchRouter>
            <a routerLink="/welcome">Welcome</a>
          </li>
          <li nz-menu-item nzMatchRouter>
            <a>Monitor</a>
          </li>
          <li nz-menu-item nzMatchRouter>
            <a>Workplace</a>
          </li>
        </ul>
      </li>

      <li nz-submenu nzIcon="form" nzOpen nzTitle="Gestion des membres">
        <ul>
          <li nz-menu-item nzMatchRouter>
            <a (click)="listAccount()">Liste</a>
          </li>
          <li nz-menu-item nzMatchRouter>
            <a (click)="addAccount()">Ajout</a>
          </li>
        </ul>
      </li>

      <li nz-submenu nzIcon="form" nzOpen nzTitle="Gestion des notes">
        <ul>
          <li nz-menu-item nzMatchRouter>
            <a (click)="listNote()">Liste</a>
          </li>
          <li nz-menu-item nzMatchRouter>
            <a (click)="addNote()">Ajout</a>
          </li>
        </ul>
      </li>
      <li nz-submenu nzIcon="form" nzOpen nzTitle="Transmission">
        <ul>
          <li nz-menu-item nzMatchRouter>
            <a (click)="repayment()">Remboursement</a>
          </li>
        </ul>
      </li>

    </ul>
  </nz-sider>
  <nz-layout>
    <nz-header>
      <div class="app-header">
        <span (click)="isCollapsed = !isCollapsed" class="header-trigger">
            <span [nzType]="isCollapsed ? 'menu-unfold' : 'menu-fold'"
                  class="trigger"
                  nz-icon
            ></span>
        </span>
      </div>
    </nz-header>
    <nz-content>
      <div class="inner-content">
        <router-outlet></router-outlet>
      </div>
    </nz-content>
  </nz-layout>
</nz-layout>
